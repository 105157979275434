import { useState, useEffect, useCallback } from 'react'
import { ReactComponent as WeeklyPredictionSaveAsSvg } from '../../images/WeeklyPrediction/weekly-prediction-save-as.svg'
import WeeklyPredictionEditSvg from '../../images/WeeklyPrediction/weekly-prediction-edit.svg'
import { ReactComponent as WeeklyPredictionMarkSvg } from '../../images/WeeklyPrediction/weekly-prediction-mark.svg'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import { updateQuestionState } from '../../services/practice.services'
import {
  toastError,
  toastSuccess,
  handleExceptionError,
} from '../../utils/utils'
import { labelOptions } from '../../constants'
import { useAuth } from '../../providers/AuthProvider'
import SelectLabelPopupQuestionState from '../SelectLabelPopupQuestionState'

interface ReadingQuestionStatesProps {
  question: PracticeMockQuestion | undefined
}

const ReadingQuestionStates: React.FC<ReadingQuestionStatesProps> = ({
  question,
}) => {
  const { userProfile } = useAuth()

  const [isBookmark, setIsBookmark] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState<number | null>(null)
  const [activePopup, setActivePopup] = useState(false)
  const [isEditingNote, setIsEditingNote] = useState(false)
  const [noteInput, setNoteInput] = useState<string>('')

  useEffect(() => {
    if (question && question.addons) {
      setIsBookmark(question.addons.examCount === 1)
      setSelectedLabel(question.addons.label)
      setNoteInput(question.addons.note || '')
    }
  }, [question])

  // Update Bookmark Status
  const handleUpdateBookmark = useCallback(async () => {
    if (question && userProfile?.userId) {
      try {
        const response = await updateQuestionState(
          userProfile.userId,
          question.id,
          2,
          question.category_id,
          '',
        )

        if (response.data.success) {
          toastSuccess('Bookmark updated successfully!')
          setIsBookmark((prev) => !prev)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
        toastError('Something went wrong!')
      }
    }
  }, [question, userProfile])

  // Handle Label Selection
  const handleLabelSelect = useCallback(
    async (labelValue: number) => {
      if (question && userProfile?.userId) {
        try {
          const response = await updateQuestionState(
            userProfile.userId,
            question.id,
            labelValue,
            question.category_id,
            '',
          )

          if (response.data.success) {
            toastSuccess('Label updated successfully!')
            setSelectedLabel(labelValue)
            setActivePopup(false)
          } else {
            toastError(response.data.message)
          }
        } catch (error) {
          handleExceptionError(error)
          toastError('Something went wrong!')
        }
      }
    },
    [question, userProfile],
  )

  // Get Label SVG Fill Color
  const getSvgFillColorForLabel = useCallback(() => {
    const matchedLabel = labelOptions.find(
      (label) => label.value === selectedLabel,
    )
    return matchedLabel ? matchedLabel.color : '#F0F0F0'
  }, [selectedLabel])

  // Toggle Edit Note
  const toggleEditNote = useCallback(() => {
    setIsEditingNote((prev) => !prev)
  }, [])

  // Handle Note Change
  const handleNoteChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNoteInput(event.target.value)
    },
    [],
  )

  // Save Note
  const saveNote = useCallback(async () => {
    if (question && userProfile?.userId) {
      try {
        const response = await updateQuestionState(
          userProfile.userId,
          question.id,
          1,
          question.category_id,
          noteInput,
        )

        if (response.data.success) {
          toastSuccess('Note updated successfully!')
          setIsEditingNote(false)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
        toastError('Failed to update the note!')
      }
    }
  }, [question, userProfile, noteInput])

  // Cancel Edit Note
  const cancelEditNote = useCallback(() => {
    setIsEditingNote(false)
    setNoteInput(question?.addons?.note ?? noteInput) // Restore original note
  }, [question])

  return (
    <div className="flex flex-col pt-4">
      <div className="flex items-center">
        <WeeklyPredictionSaveAsSvg
          title="Bookmark"
          fill={isBookmark ? '#2C62FF' : 'none'}
          className="cursor-pointer hover:scale-105"
          onClick={handleUpdateBookmark}
        />
        <img
          src={WeeklyPredictionEditSvg}
          alt="Add note"
          title="Add Note"
          className="ml-2 hover:scale-105 cursor-pointer"
          onClick={toggleEditNote}
        />
        <div className="relative">
          <WeeklyPredictionMarkSvg
            title="Label Type"
            fill={getSvgFillColorForLabel()}
            className="cursor-pointer ml-2 hover:scale-105"
            onClick={() => setActivePopup(!activePopup)}
          />
          <SelectLabelPopupQuestionState
            activePopup={activePopup}
            setActivePopup={setActivePopup}
            handleLabelSelect={handleLabelSelect}
            question={question}
          />
        </div>
      </div>

      <p className="text-bodym text-neutrals-1 mt-4">
        My note:{' '}
        {isEditingNote ? (
          <div className="flex items-center gap-2 mt-2">
            <input
              type="text"
              // maxLength={30}
              value={noteInput}
              onChange={handleNoteChange}
              className="text-bodyr border p-1 rounded-md flex-grow"
              placeholder="Type your note here..."
            />
            <button
              className={`text-white px-2 py-1 rounded ${
                noteInput.trim()
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
              onClick={saveNote}
            >
              ✓
            </button>
            <button
              className="text-white bg-red-600 px-2 py-1 rounded hover:bg-red-700"
              onClick={cancelEditNote}
            >
              ✖
            </button>
          </div>
        ) : (
          <span className="text-bodyr">{noteInput}</span>
        )}
      </p>
    </div>
  )
}

export default ReadingQuestionStates
