import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as MicrophoneIcon } from '../../../images/SystemRequirement/sr-microphone-icon.svg'
import { ReactComponent as RightArrowIcon } from '../../../images/SystemRequirement/sr-right-arrow-icon.svg'
import { useAuth } from '../../../providers/AuthProvider'
import { doTest1, startTestNow1 } from '../../../services/mockTest.services'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { navigateToMockTest, handleExceptionError } from '../../../utils/utils'

interface RecordingState {
  mediaRecorder: MediaRecorder | null
  audioBlob: Blob | null
}

const SRMockTestPage = () => {
  const navigate = useNavigate()
  const { userProfile } = useAuth()
  const [timer, setTimer] = useState<number>(5)
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [recordingState, setRecordingState] = useState<RecordingState>({
    mediaRecorder: null,
    audioBlob: null,
  })
  const [buyId, setBuyId] = useState<number | undefined>(undefined)
  const [testId, setTestId] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const buyIdStorage = localStorage.getItem('buyIdPTEMock')
    const testIdStorage = localStorage.getItem('testIdPTEMock')

    const fetchStartTestNow1 = async () => {
      if (userProfile?.userId && buyIdStorage && testIdStorage) {
        const localBuyId = Number(buyIdStorage)
        const localTestId = Number(testIdStorage)
        setBuyId(localBuyId)
        setTestId(localTestId)
        try {
          const response = await startTestNow1(
            userProfile.userId,
            localBuyId,
            localTestId,
          )
          if (!response.data.success) {
            setErrorMsg(response.data.message)
          }
        } catch (error) {
          if (error instanceof TypeError) {
            setErrorMsg(error.message)
          } else {
            setErrorMsg('Something went wrong!')
          }
        } finally {
          setIsLoading(false)
        }
      } else {
        console.error('Userprofile or buyId or testId not found!')
      }
    }

    fetchStartTestNow1()
  }, [userProfile])

  const startRecording = async () => {
    setIsRecording(true)

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const recorder = new MediaRecorder(stream)
      const chunks: BlobPart[] = []

      recorder.ondataavailable = (event) => {
        chunks.push(event.data)
      }

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' })
        setRecordingState({ mediaRecorder: recorder, audioBlob: blob })
      }

      recorder.start()

      let remainingTime = 6
      const interval = setInterval(() => {
        remainingTime--
        setTimer(remainingTime)
        if (remainingTime === 0) {
          clearInterval(interval)
          setIsRecording(false)
          recorder.stop()
        }
      }, 1000)
    } catch (error) {
      console.error('Error accessing microphone:', error)
      setIsRecording(false)
    }
  }

  const handleContinue = async () => {
    if (userProfile && userProfile.userId && buyId && testId) {
      try {
        const response = await doTest1(buyId, testId, userProfile.userId)
        if (response.data.success) {
          localStorage.setItem(
            'resultMockPTE',
            JSON.stringify(response.data.result),
          )
          navigateToMockTest(navigate, response.data.category_id)
        } else {
          console.error(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or buyId or testId not found!')
    }
  }

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Mock Test / System Requirement
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        {isLoading ? (
          <LoadingMessage message="Generating questions... Please wait..." />
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div className="sr-mocktest-section font-landingPrimary">
            <p className="sr-mocktest-section__title text-h1 text-info">
              Mock Test
            </p>
            <p className="sr-sectional-speaking-section__instruction text-bodyr mt-[1rem]">
              To start, click the <span className="font-bold">"Record"</span>{' '}
              button below to test your microphone, then click{' '}
              <span className="font-bold">"Continue”</span> to sectional test
            </p>
            <div className="sr-sectional-speaking-section__micro-check text-bodyr lg:w-[37.188rem] w-full my-[1rem]">
              <p className="text-h5m text-neutrals-2 mb-[1rem]">
                MICROPHONE CHECK
              </p>
              <p>
                This is an opportunity to check if your microphone is working
                properly.
              </p>
              <ol className="list-decimal text-bodyr pl-[1rem] lg:w-[36.188rem]">
                <li className="mb-[0.6rem]">
                  Make sure your headset is on and the microphone is in the
                  downward position near your mouth.
                </li>
                <li className="mb-[0.6rem]">
                  Click on the <span className="font-bold">“Record”</span>{' '}
                  button and say{' '}
                  <span className="font-bold">
                    “Testing, testing, one, two, three”
                  </span>{' '}
                  into the microphone.
                </li>
                <li className="mb-[0.6rem]">
                  After you have spoken, click on the{' '}
                  <span className="font-bold">“Stop”</span> button. Your
                  recording is now complete.
                </li>
                <li className="mb-[0.6rem]">
                  Now click on the <span className="font-bold">“Playback”</span>{' '}
                  button. You should clearly hear yourself speaking.
                </li>
                <li className="mb-[0.6rem]">
                  If you can not hear your voice clearly, please raise your
                  hand.
                </li>
              </ol>
              <p className="text-bodyr text-neutrals-2 mt-[1rem]">
                During the test, you will not have{' '}
                <span className="font-bold">“Record”</span>,{' '}
                <span className="font-bold">“Playback”</span> and{' '}
                <span className="font-bold">“Stop”</span> buttons. The voice
                recording will start automatically.
              </p>
            </div>
            <div className="sr-mocktest-section__audio-record mt-[1rem]">
              {!isRecording && !recordingState.audioBlob && (
                <button
                  onClick={startRecording}
                  className="lg:w-[8.625rem] w-full h-[2.5rem] text-h5r text-white bg-info hover:bg-[#1890FF] flex justify-center items-center gap-2 rounded-lg"
                >
                  <MicrophoneIcon />
                  Record
                </button>
              )}
              {!isRecording ? (
                recordingState.audioBlob && (
                  <div>
                    <audio
                      controls
                      className="lg:w-[30rem] w-full"
                      src={URL.createObjectURL(recordingState.audioBlob)}
                    />
                    <button
                      className="mt-[1rem] lg:w-[8.625rem] w-full h-[2.5rem] bg-info hover:bg-[#1890FF] text-h5r text-white flex justify-center items-center gap-2 rounded-lg"
                      onClick={handleContinue}
                    >
                      <RightArrowIcon />
                      Continue
                    </button>
                  </div>
                )
              ) : (
                <div className="w-[17rem] h-[2.875rem] bg-[#F1EFF2] text-bodyr text-neutrals-2 flex justify-center items-center rounded-2xl">
                  <p>00:0{timer} seconds</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SRMockTestPage
