import React from 'react'

interface ImageModalProps {
  imageUrl: string
  onClose: () => void
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, onClose }) => {
  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-70 p-4"
      onClick={onClose} // Close when clicking outside
    >
      <div
        className="relative bg-white p-2 rounded-lg shadow-lg max-w-[90vw] max-h-[90vh] flex items-center justify-center"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-black text-white text-lg rounded-full w-8 h-8 flex items-center justify-center hover:bg-gray-700"
        >
          ✕
        </button>

        {/* Large Image Display */}
        <div className="flex justify-center">
          <img
            src={imageUrl}
            alt="Student Score Report"
            className="max-w-full max-h-[80vh] object-contain rounded-lg"
          />
        </div>
      </div>
    </div>
  )
}

export default ImageModal
