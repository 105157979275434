import React from 'react'
import { useParams } from 'react-router-dom'
import SubscribeForm from '../../components/LandingPage/SubcribeForm'
import SkillCTASection from './AcademicSkill/SkillCTASection'
import BlogImage from '../../images/Landingpage/Blog/popular_post.png'
import { Helmet } from 'react-helmet-async'

interface BlogPostProps {
  blogPosts: {
    slug: string
    title: string
    content: string
    time: string
    image: string
  }[]
}

const BlogPost: React.FC<BlogPostProps> = ({ blogPosts }) => {
  const { slug } = useParams<{ slug: string }>()

  // Find the blog post by slug
  const blogPost = blogPosts.find((post) => post.slug === slug)

  if (!blogPost) {
    return <p>Blog post not found.</p>
  }
  // JSON-LD structured data for SEO
  const blogSchema = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: blogPost.title,
    author: {
      '@type': 'Person',
      name: 'PTE Master Team', // Replace with actual author if available
    },
    publisher: {
      '@type': 'Organization',
      name: 'PTE Master',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.ptemaster.com/pte-master-logo.ico', // Replace with actual logo URL
      },
    },
    datePublished: '2024-02-10', // You can replace this with dynamic date if available
    dateModified: '2024-02-10',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.ptemaster.com/blog/${blogPost.slug}`,
    },
    articleBody: blogPost.content,
    image: blogPost.image,
    url: `https://www.ptemaster.com/blog/${blogPost.slug}`,
    wordCount: blogPost.content.split(' ').length,
    timeRequired: blogPost.time || '5 min read',
  }

  return (
    <div>
      <Helmet>
        <title>{`PTE Master | ${blogPost.title}`}</title>
        <script type="application/ld+json">{JSON.stringify(blogSchema)}</script>
      </Helmet>
      <div className="w-full px-6 lg:px-12 py-6 lg:py-12 flex flex-col lg:flex-row gap-6">
        {/* Left Section: Blog Content */}
        <div className="w-full lg:w-2/3 pr-0 lg:pr-12">
          <h1 className="text-h1 mb-6">{blogPost.title}</h1>
          <div className="text-bodyr text-[#595959]">{blogPost.content}</div>
        </div>

        {/* Right Section: Popular Posts and Subscribe Form */}
        <div className="w-full lg:w-1/3 space-y-6">
          <div>
            <h2 className="text-h5m text-[#00000073] mb-4">Popular Posts</h2>
            <div className="rounded-md shadow-md overflow-hidden">
              <img
                src={BlogImage}
                alt="Popular Post"
                className="w-full object-cover"
              />
              <div className="p-4">
                <button className="px-6 py-2 bg-yellow-500 text-white rounded-full text-footnote">
                  View Practice Demo
                </button>
                <h3 className="text-h5m text-[#141414] mt-4">
                  Top Tips for Agents: Avoiding Common Buyer Pitfalls
                </h3>
                <p className="text-h5m text-[#BFBFBF] mt-2">6 min read</p>
              </div>
            </div>
          </div>
          <SubscribeForm />
        </div>
      </div>
      <SkillCTASection />
    </div>
  )
}

export default BlogPost
