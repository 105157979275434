import React, { useState } from 'react'
import YoutubeVideoType from '../../models/youtubeVideoType.model'
import YoutubeVideo from '../../models/youtubeVideo.model'
import { ReactComponent as DropDownArrowIcon } from '../../images/Community/cmnt-dropdown-arrow-icon.svg'
import PTEVideoCard from '../PTEVideosPage/PTEVideoCard'

const RecentVideo: React.FC<{
  youtubeVideoTypes: YoutubeVideoType[]
  youtubeVideos: YoutubeVideo[]
}> = ({ youtubeVideoTypes, youtubeVideos }) => {
  const [selectedYoutubeVideoType, setSelectedYoutubeVideoType] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleItemClick = (item: YoutubeVideoType) => {
    setSelectedYoutubeVideoType(item.typeId)
    setIsOpen(false)
  }

  return (
    <div className="p-8">
      <p className="text-h2 text-neutrals-1">Recent Videos</p>

      <div className="lg:hidden lg:mt-[2rem] mt-[1rem] md:w-full w-full relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-[2rem] py-[1.2rem] px-[1rem] bg-white border border-1 rounded-lg text-h5r font-landingPrimary flex justify-between items-center"
        >
          {youtubeVideoTypes.find(
            (type) => type.typeId === selectedYoutubeVideoType,
          )?.typeName ?? 'Select Video Type'}
          <DropDownArrowIcon fill="#00000040" />
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-lg">
            {youtubeVideoTypes.map((type) => (
              <li
                key={type.typeId}
                onClick={() => handleItemClick(type)}
                className="cursor-pointer px-[12px] py-[5px] text-h5r font-landingPrimary hover:bg-info hover:text-white"
              >
                {type.typeName}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* <nav className="hidden lg:flex justify-between items-center bg-gray-100 mt-[1rem] lg:w-[50%] w-full lg:h-[2.875rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
        <ul className="flex text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
          {youtubeVideoTypes.map((type) => (
            <li
              key={type.typeId}
              onClick={() => handleItemClick(type)}
              className={`cursor-pointer flex items-center justify-center flex-grow lg:w-1/2 rounded-lg ${
                selectedYoutubeVideoType === type.typeId
                  ? 'text-h5r text-neutrals-4 bg-info'
                  : ''
              }`}
            >
              {type.typeName}
            </li>
          ))}
        </ul>
      </nav> */}

      <div className="youtube-videos-section__list mt-[2rem]">
        <div className="youtube-videos-section__list_items mt-[1rem] flex flex-wrap gap-10">
          {youtubeVideos.map((video: YoutubeVideo) => (
            <PTEVideoCard key={video.id} youtubeVideo={video} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentVideo
