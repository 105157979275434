import React from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithGoogle } from '../../../configs/firebase'
import { ReactComponent as GoogleIcon } from '../../../images/Landingpage/google-icon.svg'
import Path from '../../../routes/Path'
import { useAuth } from '../../../providers/AuthProvider'
import { toastError, toastSuccess } from '../../../utils/utils'

interface GoogleLoginButtonProps {
  disabled: boolean
  setDisabled: (value: boolean) => void
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  disabled,
  setDisabled,
}) => {
  const { socialLogin } = useAuth()
  const navigate = useNavigate()

  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle()

      if (user.email && user.displayName && user.photoURL) {
        await handleSocialLogin(user.email, user.displayName, user.photoURL)
      }
    } catch (error) {
      setDisabled(false)
      console.error('Error during Google sign-in:', error)
    }
  }

  const handleSocialLogin = async (
    email: string,
    displayName: string,
    photoURL: string,
  ) => {
    try {
      await socialLogin(email, displayName, photoURL)
      setDisabled(true)
      toastSuccess('Login successful!')

      setTimeout(() => {
        navigate(Path.home.path)
      }, 3000)
    } catch (error: any) {
      setDisabled(false)
      toastError(error.message)
    }
  }

  return (
    <>
      <button
        className={`w-full text-bodyr font-landingPrimary px-[0.688rem] py-[0.938rem] border border-[#0000000F] flex justify-center items-center gap-x-4 rounded-lg ${disabled ? 'text-gray-100 bg-gray-300 cursor-not-allowed' : 'hover:bg-[#1890FF] hover:text-white'}`}
        onClick={handleGoogleSignIn}
        disabled={disabled}
      >
        <GoogleIcon /> Continue with Google
      </button>
    </>
  )
}

export default GoogleLoginButton
