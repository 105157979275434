import axiosInstance from '../configs/axiosConfig'

export function practiceWeeklyPrediction(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`practice/practice`, data)
}

export function getPracticeQuestion(
  userId: number,
  categoryId: number,
  qtype: number,
  qquality: number,
  myprac: number,
  labelselect: number,
  frecuency: number,
  qattempt: number,
  search: string,
  questionId?: number,
) {
  const data: {
    user_id: number
    categoryId: number
    qtype: number
    qquality: number
    myprac: number
    labelselect: number
    frecuency: number
    qattempt: number
    search: string
    questionId?: number
  } = {
    user_id: userId,
    categoryId,
    qtype,
    qquality,
    myprac,
    labelselect,
    frecuency,
    qattempt,
    search,
  }

  if (questionId !== undefined) {
    data.questionId = questionId // Add questionId conditionally
  }

  return axiosInstance.post(`practice/practice-question`, data)
}

export function updateQuestionState(
  userId: number,
  questionId: number,
  stateType: number,
  questionCategory: number,
  note: string,
) {
  const data = {
    user_id: userId,
    type: stateType,
    id: questionId,
    question_cat: questionCategory,
    note: note,
  }

  return axiosInstance.post(`practice/update-question-state`, data)
}

export function practiceNowWithFilter(
  userId: number,
  categoryId: number,
  questionId: number,
  qType: number,
  qQuality: number,
  myPrac: number,
  labelSelect: number,
  frequency: number,
) {
  const data = {
    userId: userId,
    categoryId: categoryId,
    questionId: questionId,
    qType: qType,
    qQuality: qQuality,
    myPrac: myPrac,
    labelSelect: labelSelect,
    frequency: frequency,
  }

  return axiosInstance.post(`practice/practice-now-with-filter`, data)
}

export function updateAttempt(
  userId: number,
  categoryId: number,
  questionId: number,
  qType: number,
  qQuality: number,
  myPrac: number,
  labelSelect: number,
  frequency: number,
) {
  const data = {
    userId: userId,
    categoryId: categoryId,
    questionId: questionId,
    qType: qType,
    qQuality: qQuality,
    myPrac: myPrac,
    labelSelect: labelSelect,
    frequency: frequency,
  }

  return axiosInstance.post(`practice/update-attempt`, data)
}

export function loadMoreSamples(questionId: number, offset: number) {
  const data = {
    questionId: questionId,
    offset: offset,
  }

  return axiosInstance.post(`practice/load-more-samples`, data)
}

export function deductCreditUser(userId: number, category: number) {
  const data = {
    userId: userId,
    category: category,
  }

  return axiosInstance.post(`practice/deduct-credit-user`, data)
}

export function getAllMockQuestions(
  userId: number,
  categoryId: number,
  questionId: number,
  qType: number,
  qQuality: number,
  myPrac: number,
  labelSelect: number,
  frequency: number,
) {
  const data = {
    userId: userId,
    catId: categoryId,
    questionId: questionId,
    qType: qType,
    qQuality: qQuality,
    myPrac: myPrac,
    labelSelect: labelSelect,
    frequency: frequency,
  }

  return axiosInstance.post(`practice/all-mock-question`, data)
}

export function createResultListeningPractice(
  userId: number,
  pracResultQuestionId: number,
  pracResultCategoryId: number,
  userAnswer: string,
  mark?: number,
) {
  const data: {
    userId: number
    pracResultQuestionId: number
    pracResultCategoryId: number
    userAnswer: string
    mark?: number
  } = {
    userId: userId,
    pracResultQuestionId: pracResultQuestionId,
    pracResultCategoryId: pracResultCategoryId,
    userAnswer: userAnswer,
  }

  if (mark !== undefined) {
    data.mark = mark
  }

  return axiosInstance.post(`practice/listening`, data)
}

export function createResultWritingPractice(
  userId: number,
  mocqdesc: string,
  pracResultQuestionId: number,
  pracResultCategoryId: number,
  userAnswer: string,
) {
  const data = {
    userId: userId,
    mocqdesc: mocqdesc,
    pracResultQuestionId: pracResultQuestionId,
    pracResultCategoryId: pracResultCategoryId,
    userAnswer: userAnswer,
  }

  return axiosInstance.post(`practice/writing`, data)
}

export function storePracticeData(
  pracResultQuestionId: number,
  pracResultCategoryId: number,
  pracResultAudio: string,
  pracFluency: number,
  pracPronoun: number,
  pracContent: number,
  pracGrammar: number,
  pracForm: number,
  pracVocab: number,
  pracSpelling: number,
  pracResultMarks: number,
  pracTranscript: string,
  pracResultUserId: number,
  audioData?: File,
) {
  const formData = new FormData()
  formData.append('pracResultQuestionId', pracResultQuestionId.toString())
  formData.append('pracResultCategoryId', pracResultCategoryId.toString())
  formData.append('pracResultAudio', pracResultAudio)
  formData.append('pracFluency', pracFluency.toString())
  formData.append('pracPronoun', pracPronoun.toString())
  formData.append('pracContent', pracContent.toString())
  formData.append('pracGrammar', pracGrammar.toString())
  formData.append('pracForm', pracForm.toString())
  formData.append('pracVocab', pracVocab.toString())
  formData.append('pracSpelling', pracSpelling.toString())
  formData.append('pracResultMarks', pracResultMarks.toString())
  formData.append('pracTranscript', pracTranscript)
  formData.append('pracResultUserId', pracResultUserId.toString())

  if (audioData) {
    formData.append('audioData', audioData)
  }

  return axiosInstance.post(`speaking/store-practice-data`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function addProgressSpeaking(
  QuestionId: number,
  CategoryId: number,
  Fluency: number,
  Pronoun: number,
  Content: number,
  Marks: number,
  UserId: number,
) {
  const data = {
    'questionId': QuestionId,
    'catId': CategoryId,
    'fluency': Fluency.toString(),
    'pronunciation': Pronoun.toString(),
    'content': Content.toString(),
    'marks': Marks.toString(),
    'userId': UserId,
  }

  return axiosInstance.post(`speaking/add-progress-speaking`, data)
}

export function createResultReadingPractice(
  userId: number,
  mocqdesc: string,
  questionId: string | null,
  categoryId: number,
  userAnswer: string,
  totalOfQuestion: number,
) {
  const data = {
    userId: userId,
    mocqdesc: mocqdesc,
    pracResultQuestionId: questionId,
    pracResultCategoryId: categoryId,
    userAnswer: userAnswer,
    totalOfQuestion: totalOfQuestion,
  }

  return axiosInstance.post(`practice/reading`, data)
}
