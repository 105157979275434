import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'
import 'react-toastify/dist/ReactToastify.css'
import { ReactComponent as WeeklyPredictionSaveAsSvg } from '../../images/WeeklyPrediction/weekly-prediction-save-as.svg'
import WeeklyPredictionEditSvg from '../../images/WeeklyPrediction/weekly-prediction-edit.svg'
import { ReactComponent as WeeklyPredictionMarkSvg } from '../../images/WeeklyPrediction/weekly-prediction-mark.svg'
import { QuestionModelId } from '../../models/QuestionModels'
import MockQuestion from '../../models/mockQuestion.model'
import { useAuth } from '../../providers/AuthProvider'
import { getPracticeQuestion } from '../../services/practice.services'
import { isRealExamStatus, isWithinLastSevenDays } from '../../utils/utils'
import ErrorMessage from '../Items/ErrorMessage'
import LoadingMessage from '../Items/LoadingMessage'
import SelectLabelPopup from '../SelectLabelPopup'
import useQuestionState from '../../hook/useQuestionState'

interface DescribeImageListProps {
  categoryType: QuestionModelId.S_DescribeImage
  practiceType: number
  labelType: number
}

const DescribeImageList: React.FC<DescribeImageListProps> = ({
  categoryType,
  practiceType,
  labelType,
}) => {
  const navigate = useNavigate()
  const { userProfile } = useAuth()

  const [visibleQuestions, setVisibleQuestions] = useState(6)
  const [activePopup, setActivePopup] = useState<number | null>(null) // Track the popup visibility for a question
  const [isLoading, setIsLoading] = useState(true)

  const {
    mockQuestions,
    setMockQuestions,
    notes,
    setNotes,
    editingNoteId,
    setEditingNoteId,
    handleLabelSelect,
    handleUpdateBookmark,
    getSvgFillColorForLabel,
    getSvgFillColorForBookmark,
    toggleEditNote,
    handleNoteChange,
    saveNote,
  } = useQuestionState(userProfile)

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      try {
        getPracticeQuestion(
          userProfile.userId, // userId
          Number(categoryType), // categoryId
          0, // qtype
          0, // qquality
          0, // myprac
          labelType, // labelselect
          4, // frecuency
          practiceType, // qattempt
          '', // search
        ).then((response) => {
          if (response.data.success) {
            setMockQuestions(response.data.mockQuestions)

            const initialNotes: { [key: number]: string } = {}
            response.data.mockQuestions.forEach((q: MockQuestion) => {
              initialNotes[q.id] = q.addons?.note || ''
            })
            setNotes(initialNotes)
            setIsLoading(false)
          } else {
            console.error(response.data.message)
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [
    userProfile,
    categoryType,
    labelType,
    practiceType,
    setNotes,
    setMockQuestions,
  ])

  const showMoreQuestions = () => {
    setVisibleQuestions((prev) => prev + 8)
  }

  const handleSelectQuestion = (question: MockQuestion) => {
    localStorage.setItem('questionIdPTE', String(question.id))
    localStorage.setItem('questionTypePTE', String(question.category_id))
    localStorage.setItem('ptePracticeQuestionLabel', labelType.toString())
    localStorage.setItem('ptePracticeQuestionAttempt', practiceType.toString())

    if (testType === 'practice') {
      navigate(Path.practiceTest.describeImage.path)
    } else {
      navigate(Path.describeImage.path)
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingMessage message="Loading questions..." />
      ) : mockQuestions.length === 0 ? (
        <ErrorMessage message="No questions available..." />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 min-[2000px]:grid-cols-3 gap-8">
          {mockQuestions.slice(0, visibleQuestions).map((question, index) => (
            <div
              key={index}
              className="col-span-1 cursor-pointer rounded-lg hover:shadow-lg"
              onClick={() => handleSelectQuestion(question)}
            >
              <div className="border border-[1px]-[#D9D9D9] p-4 rounded-lg">
                <img
                  src={question.image}
                  alt="describe"
                  className="w-full rounded-lg h-[300px]"
                />

                <div className="flex text-footnote pt-2">
                  {isWithinLastSevenDays(question.created_at) && (
                    <p className=" text-white bg-red-600 px-2 mr-2 rounded-full">
                      New
                    </p>
                  )}
                  {question.highly_repeated > 0 && (
                    <p className=" text-white bg-[#28a745] px-2 mr-2 rounded-full">
                      Predict
                    </p>
                  )}
                  {isRealExamStatus(question.category_id) && (
                    <p className="text-white bg-[#6c757d] px-2 mr-2 rounded-full">
                      Real Exam
                    </p>
                  )}
                  <p className="text-neutrals-2">#{question.id}</p>
                </div>
                <p className="text-footnote text-[#8C8C8C] pt-2">
                  This question has been practiced {question.frequency} time(s)
                  by other students
                </p>
                <span className="text-bodyr text-neutrals-2 font-bold mt-2">
                  My Note:{' '}
                  {editingNoteId === question.id ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        // maxLength={30}
                        value={notes[question.id] || ''}
                        className="text-bodyr w-full border rounded px-2 py-1 mr-2"
                        onChange={(e) =>
                          handleNoteChange(question.id, e.target.value)
                        }
                        onClick={(e) => e.stopPropagation()}
                      />
                      <button
                        className={`text-white px-2 py-1 rounded mr-2 ${
                          notes[question.id]?.trim()
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : 'bg-gray-400 cursor-not-allowed'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation() // Prevent click event from propagating
                          saveNote(question)
                        }}
                      >
                        ✓
                      </button>
                      <button
                        className="text-white bg-red-600 px-2 py-1 rounded hover:bg-red-700"
                        onClick={(e) => {
                          e.stopPropagation() // Prevent click event from propagating
                          // Restore the original note
                          handleNoteChange(
                            question.id,
                            question.addons?.note || '',
                          )
                          setEditingNoteId(null) // Close the input
                        }}
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    <span className="text-footnote">
                      {notes[question.id] || ''}
                    </span>
                  )}
                </span>
                <div className="relative flex justify-end mt-2">
                  <WeeklyPredictionSaveAsSvg
                    title="Bookmark"
                    fill={getSvgFillColorForBookmark(question)}
                    className="cursor-pointer mr-2 hover:scale-105"
                    onClick={(e) => {
                      e.stopPropagation() // Prevent click event from propagating
                      handleUpdateBookmark(question)
                    }}
                  />
                  <img
                    src={WeeklyPredictionEditSvg}
                    alt="Add note"
                    title="Add Note"
                    className="mr-2 hover:scale-105 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation() // Prevent click event from propagating
                      toggleEditNote(question.id)
                    }}
                  />
                  <div className="relative">
                    <WeeklyPredictionMarkSvg
                      title="Label Type"
                      fill={getSvgFillColorForLabel(question)}
                      className="cursor-pointer mr-2 hover:scale-105"
                      onClick={(e) => {
                        e.stopPropagation() // Prevent click event from propagating
                        setActivePopup(activePopup === index ? null : index)
                      }}
                    />
                    <SelectLabelPopup
                      index={index}
                      activePopup={activePopup}
                      setActivePopup={setActivePopup}
                      handleLabelSelect={handleLabelSelect}
                      question={question}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {visibleQuestions < mockQuestions.length && (
            <button
              onClick={showMoreQuestions}
              className="w-full col-span-full text-bodyr text-white py-2 rounded-sm"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              Show More...
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default DescribeImageList
