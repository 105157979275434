import { useState, useEffect, useRef } from 'react'
import logo from '../images/logo.svg'
import searchIcon from '../images/Navbar/navbar-search.svg'
import shoppingIcon from '../images/Navbar/navbar-shopping-cart.svg'
// import messageIcon from '../images/Navbar/navbar-message.svg'
import barChartIcon from '../images/Navbar/navbar-bar-chart.svg'
// import notificationIcon from '../images/Navbar/navbar-notification.svg'
import arrowDownIcon from '../images/Navbar/navbar-arrow-down.svg'
import menuIcon from '../images/Navbar/navbar-menu.svg'
import signoutIcon from '../images/Navbar/navbar-sign-out.svg'
// import settingIcon from '../images/Navbar/navbar-setting.svg'
import bagIcon from '../images/Navbar/navbar-shopping-bag.svg'
import { Link, useLocation } from 'react-router-dom'
import MobileSideBar from './MobileSideBar'
import { useNavigate } from 'react-router-dom'
import Path from '../routes/Path'
import { useAuth } from '../providers/AuthProvider'
import { categoryItems } from '../constants'
import userProfileIcon from '../images/Navbar/navbar-user.svg'

const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCategories, setFilteredCategories] = useState(categoryItems)
  const [isResultListVisible, setIsResultListVisible] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { logout, userProfile } = useAuth()

  const navItems = [
    // {
    //   path: '/live-chat',
    //   icon: messageIcon,
    //   alt: 'Messages',
    //   notifications: 0,
    // },
    {
      path: '/my-performance',
      icon: barChartIcon,
      alt: 'Analytics',
      notifications: 0,
    },
    // {
    //   path: '/notifications',
    //   icon: notificationIcon,
    //   alt: 'Notifications',
    //   notifications: 0,
    // },
  ]

  const handleSignOut = async () => {
    try {
      await logout()
    } catch (error: any) {
      console.error(error.response?.data?.message)
    }
  }

  const handleNavigate = (path: string) => {
    setIsDropdownOpen(false) // Close the dropdown
    navigate(path)
  }

  const handleChooseQuestionType = (categoryId: number) => {
    setSearchTerm('')
    localStorage.setItem('questionTypePTE', String(categoryId))
    navigate(Path.weeklyPredictionCourse.path)
  }

  const dropdownItems = [
    {
      path: Path.userProfile.path,
      icon: userProfileIcon,
      alt: 'User icon',
      label: 'Profile',
    },
    {
      path: Path.yourPurchase.path,
      icon: bagIcon,
      alt: 'Cart icon',
      label: 'My Purchase',
    },
    // {
    //   path: Path.userProfile.path,
    //   icon: settingIcon,
    //   alt: 'Setting icon',
    //   label: 'Settings',
    // },
    {
      onClick: handleSignOut,
      icon: signoutIcon,
      alt: 'Signout icon',
      label: 'Sign Out',
    },
  ]

  useEffect(() => {
    setIsDropdownOpen(false) // Close the dropdown
  }, [location])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchTerm(value)
    if (value === '') {
      setFilteredCategories([])
      setIsResultListVisible(false)
    } else {
      const filtered = categoryItems.filter((item) =>
        item.category.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredCategories(filtered)
      setIsResultListVisible(true)
    }
  }

  const handleInputFocus = () => {
    if (searchTerm !== '') {
      setIsResultListVisible(true)
    }
  }
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleInputBlur = () => {
    setIsResultListVisible(false)
  }

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="sticky top-0 left-0 w-full bg-white z-40 px-4 py-4">
      <div className="flex justify-between items-center">
        <Link to={Path.home.path}>
          <img src={logo} alt="Logo" width={150} height={50} />
        </Link>

        <div className="hidden lg:flex items-center w-1/3 py-1 pl-4 border-2 border-gray-300 rounded-lg hover:border-gray-400 relative">
          <img
            src={searchIcon}
            alt="Search"
            className="h-6 w-6 text-gray-600 mr-2 hover:scale-105"
          />
          <input
            ref={inputRef}
            className="flex-grow h-9 px-2 border-none outline-none"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {isResultListVisible && filteredCategories.length > 0 && (
            <div
              className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1 z-50"
              onMouseEnter={() => setIsResultListVisible(true)}
              onMouseLeave={() => setIsResultListVisible(false)}
            >
              {filteredCategories.slice(0, 6).map((item) => (
                <div
                  key={item.id}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleChooseQuestionType(item.id)}
                >
                  {item.category}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="hidden lg:flex items-center gap-4">
          <Link
            to="/cart"
            className="relative pr-4 py-2 border-r-2 border-[#0000000F]"
          >
            <img
              src={shoppingIcon}
              alt="Shopping"
              className="w-6 hover:scale-105"
              width={24}
              height={24}
            />
          </Link>
          <div className="flex gap-4 pr-4 py-2 border-r-1 border-gray-100 items-center">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className="hover:scale-105 relative"
              >
                <img
                  src={item.icon}
                  alt={item.alt}
                  className="w-6"
                  width={24}
                  height={24}
                />
                {item.notifications > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-0.5">
                    {item.notifications}
                  </span>
                )}
              </Link>
            ))}
          </div>

          <div
            ref={dropdownRef}
            className={`flex gap-4 items-center relative bg-white hover:shadow-lg rounded-t-md ${!isDropdownOpen && 'rounded-b-md'} p-3 transition-all duration-300 ease-in-out`}
          >
            {/* Profile Picture */}
            <Link to="/user-profile" className="hover:scale-105">
              <img
                src={userProfile?.image}
                className="w-[40px] h-[40px] rounded-full"
                alt="Profile"
              />
            </Link>

            {/* User Info */}
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div>
                <p className="text-bodym font-medium">{userProfile?.name}</p>
                <p className="text-footnote text-gray-500">
                  {userProfile?.email}
                </p>
              </div>
              {/* Dropdown Toggle Button */}
              <button className="focus:outline-none">
                <img
                  src={arrowDownIcon}
                  alt="Arrow down"
                  className="hover:bg-gray-100"
                  width={24}
                  height={24}
                />
              </button>
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 top-full w-full bg-white border-gray-200 rounded-b-lg shadow-lg">
                {dropdownItems.map((item, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      item.onClick ? item.onClick() : handleNavigate(item.path)
                    }
                    className="w-full text-left px-4 py-2 hover:bg-[#F0F7FF] flex items-center gap-2"
                  >
                    <img src={item.icon} alt={item.alt} className="w-4 h-4" />
                    <span className="text-bodyr">{item.label}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="lg:hidden flex items-center gap-4">
          <Link to="/user-profile" className="hover:scale-105">
            <img
              src={userProfile?.image}
              className="w-[40px] h-[40px] rounded-full"
              alt="Profile"
            />
          </Link>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <img
              src={menuIcon}
              alt="Menu"
              className="h-6 w-6 hover:scale-105"
            />
          </button>
        </div>

        {/* Mobile sidebar menu */}
        <MobileSideBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </div>
    </div>
  )
}

export default NavBar
