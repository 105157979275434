import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import ProtectedRoute from './routes/ProtectedRoute'
import Path from './routes/Path'
import LandingPage from './pages/LandingPage/LandingPage'
import LoginPage from './pages/LandingPage/LoginPage'
import RegisterPage from './pages/LandingPage/RegisterPage'
import HomePage from './pages/HomePage'
import ShopPage from './pages/ShopPage'
import MyUnlockedTestsPage from './pages/MyUnlockedTestsPage'
import LastMinuteStudyPage from './pages/LastMinuteStudyPage'
import PTEVideosPage from './pages/PTEVideosPage'
import SRSectionalSpeakingPage from './pages/SystemRequirement/SectionalTest/SRSectionalSpeakingPage'
import SRSectionalListeningPage from './pages/SystemRequirement/SectionalTest/SRSectionalListeningPage'
import SRSectionaWritingPage from './pages/SystemRequirement/SectionalTest/SRSectionalWritingPage'
import SRSectionalReadingPage from './pages/SystemRequirement/SectionalTest/SRSectionalReadingPage'
import SRMockTestPage from './pages/SystemRequirement/MockTest/SRMockTestPage'
import PurchasePage from './pages/PurchasePage'
import CartPage from './pages/CartPage'
import MyPerformancePage from './pages/MyPerformancePage'
import PracticePerformancePage from './pages/PracticePerformancePage'
// import LiveChatPage from './pages/LiveChatPage'
import SupportPage from './pages/SupportPage'
import UserProfilePage from './pages/UserProfilePage'
import { ApiProvider } from './providers/ApiProvider'
import { AuthProvider, useAuth } from './providers/AuthProvider'
import RedirectIfAuthenticated from './routes/RedirectIfAuthenticated'
import Layout from './components/Layout'
import LandingPageLayout from './components/LandingPage/LandingPageLayout'
import WeeklyPredictionCourse from './pages/WeeklyPrediction/WeeklyPredictionCourse'
import WeeklyPrediction from './pages/WeeklyPrediction/WeeklyPrediction'
import ReadAloud from './pages/WeeklyPrediction/ReadAloud'
import ReTellLecture from './pages/WeeklyPrediction/RetellLecture'
import RepeatSentence from './pages/WeeklyPrediction/RepeatSentence'
import AnswerShort from './pages/WeeklyPrediction/AnswerShort'
import DescribeImage from './pages/WeeklyPrediction/DescribeImage'
import SingleAnswerMC from './pages/WeeklyPrediction/SingleAnswerMC'
import MultipleAnswerMC from './pages/WeeklyPrediction/MultipleAnswerMC'
import ReorderParagraph from './pages/WeeklyPrediction/ReorderParagraph'
import SectionalReadAloud from './pages/SectionalTest/Speaking/ReadAloud'
import SectionalRepeatSentence from './pages/SectionalTest/Speaking/RepeatSentence'
import SectionalDescribeImage from './pages/SectionalTest/Speaking/DescribeImage'
import SectionalAnswerShort from './pages/SectionalTest/Speaking/AnswerShort'
import SectionalReTellLecture from './pages/SectionalTest/Speaking/RetellLecture'
import SummarizeWrittenTextSectional from './pages/SectionalTest/Writing/SummarizeWrittenTextSectional'
import WriteEssaySectional from './pages/SectionalTest/Writing/WriteEssaySectional'
import FillInTheBlank from './pages/WeeklyPrediction/FillInTheBlank'
import ReadingFillInTheBlank from './pages/WeeklyPrediction/ReadingFillInTheBlank'
import SummarizeSpokenTextSectional from './pages/SectionalTest/Listening/SummarizeSpokenTextSectional'
import MultipleChoiceChooseMultipleAnswersSectional from './pages/SectionalTest/Listening/MultipleChoiceChooseMultipleAnswersSectional'
import FillInTheBlanksSectional from './pages/SectionalTest/Listening/FillInTheBlanksSectional'
import HighlightCorrectSummarySectional from './pages/SectionalTest/Listening/HighlightCorrectSummarySectional'
import MultipleChoiceChooseSingleAnswerSectional from './pages/SectionalTest/Listening/MultipleChoiceChooseSingleAnswerSectional'
import SelectMissingWordSectional from './pages/SectionalTest/Listening/SelectMissingWordSectional'
import HighlightIncorrectWordsSectional from './pages/SectionalTest/Listening/HighlightIncorrectWordsSectional'
import WriteFromDictationSectional from './pages/SectionalTest/Listening/WriteFromDictationSectional'
import SummarizeWrittenTextMock from './pages/MockTest/Writing/SummarizeWrittenTextMock'
import WriteEssayMock from './pages/MockTest/Writing/WriteEssayMock'
import SummarizeSpokenTextMock from './pages/MockTest/Listening/SummarizeSpokenTextMock'
import MultipleChoiceChooseMultipleAnswersMock from './pages/MockTest/Listening/MultipleChoiceChooseMultipleAnswersMock'
import FillInTheBlanksMock from './pages/MockTest/Listening/FillInTheBlanksMock'
import HighlightCorrectSummaryMock from './pages/MockTest/Listening/HighlightCorrectSummaryMock'
import MultipleChoiceChooseSingleAnswerMock from './pages/MockTest/Listening/MultipleChoiceChooseSingleAnswerMock'
import SelectMissingWordMock from './pages/MockTest/Listening/SelectMissingWordMock'
import HighlightIncorrectWordsMock from './pages/MockTest/Listening/HighlightIncorrectWordsMock'
import WriteFromDictationMock from './pages/MockTest/Listening/WriteFromDictationMock'
import HistoryDetailsMockPage from './pages/MockTest/HistoryDetailsMockPage'
import SectionalMultipleAnswerMC from './pages/SectionalTest/Reading/MultipleAnswerMC'
import SectionalSingleAnswerMC from './pages/SectionalTest/Reading/SingleAnswerMC'
import SectionalReorderParagraph from './pages/SectionalTest/Reading/ReorderParagraph'
import SectionalFillInTheBlank from './pages/SectionalTest/Reading/FillInTheBlank'
import SectionalReadingFillInTheBlank from './pages/SectionalTest/Reading/ReadingFillInTheBlank'
import TestHistoryMockPage from './pages/MockTest/TestHistoryMockPage'
import TestHistorySectionalPage from './pages/SectionalTest/TestHistorySectionalPage'
import MockReadAloudQuestion from './components/MockTest/Speaking/ReadAloudQuestion'
import MockRepeatSentenceQuestion from './components/MockTest/Speaking/RepeatSentenceQuestion'
import MockDescribeImageQuestion from './components/MockTest/Speaking/DescribeImageQuestion'
import MockReTellLectureQuestion from './components/MockTest/Speaking/ReTellLectureQuestion'
import MockAnswerShortQuestion from './components/MockTest/Speaking/AnswerShortQuestion'
import MockMultipleMCQuestion from './components/MockTest/Reading/MultipleMCQuestion'
import MockSingleMCQuestion from './components/MockTest/Reading/SingleMCQuestion'
import MockReorderParagraphQuestion from './components/MockTest/Reading/ReOrderParagraphQuestion'
import MockFillInTheBlankQuesiton from './components/MockTest/Reading/FillInTheBlankQuestion'
import MockReadingFillInTheBlanksQuestion from './components/MockTest/Reading/ReadingFillInTheBlankQuestion'
//Sectional Test - Report History
import ReportHistorySpeakingSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistorySpeakingSectionalPage'
import ReportHistoryWritingSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistoryWritingSectionalPage'
import ReportHistoryListeningSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistoryListeningSectionalPage'
import ReportHistoryReadingSectionalPage from './pages/SectionalTest/ReportHistory/ReportHistoryReadingSectionalPage'
//Weekly Prediction - Writing Skill
import SummarizeWrittenText from './pages/WeeklyPrediction/SummarizeWrittenText'
import WriteEssay from './pages/WeeklyPrediction/WriteEssay'
//Weekly Prediction - Listening Skill
import SummarizeSpokenText from './pages/WeeklyPrediction/Listening/SummarizeSpokenText'
import FillInTheBlanks from './pages/WeeklyPrediction/Listening/FillInTheBlanks'
import HighlightCorrectSummary from './pages/WeeklyPrediction/Listening/HighlightCorrectSummary'
import HighlightIncorrectWords from './pages/WeeklyPrediction/Listening/HighlightIncorrectWords'
import MultipleChoiceChooseMultipleAnswers from './pages/WeeklyPrediction/Listening/MultipleChoiceChooseMultipleAnswers'
import MultipleChoiceChooseSingleAnswer from './pages/WeeklyPrediction/Listening/MultipleChoiceChooseSingleAnswer'
import SelectMissingWord from './pages/WeeklyPrediction/Listening/SelectMissingWord'
import WriteFromDictation from './pages/WeeklyPrediction/Listening/WriteFromDictation'
//Activate Account
import ActivateAccountPage from './pages/LandingPage/ActtivateAccountPage'
import PurchaseSucceedPage from './pages/PurchaseSucceedPage'
import PurchaseCancelPage from './pages/PurchaseCancelPage'
// import { WebSocketProvider } from './providers/WebSocketProvider'
//Plan Pages
import PlanPage from './pages/PlanPage'
import PlanDetailPage from './pages/PlanDetailPage'
// Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet, HelmetProvider } from 'react-helmet-async'
// Academic Skill
import AcademicSpeakingPage from './pages/LandingPage/AcademicSpeakingPage'
import AcademicListeningPage from './pages/LandingPage/AcademicListeningPage'
import AcademicReadingPage from './pages/LandingPage/AcademicReadingPage'
import AcademicWritingPage from './pages/LandingPage/AcademicWritingPage'
import AutomateScoringPage from './pages/LandingPage/AutomateScoringPage'
import FAQsPage from './pages/LandingPage/FAQsPage'
import HowItWorksPage from './pages/LandingPage/HowItWorksPage'
import PreparationToolPage from './pages/LandingPage/PreparationToolPage'
import BlogPage from './pages/LandingPage/BlogPage'
import BlogPostPage from './pages/LandingPage/BlogPostPage'
import ContactUsPage from './pages/LandingPage/ContactUsPage'
import PrivacyPolicyPage from './pages/LandingPage/PrivacyPolicyPage'
import AIPteMockTestPage from './pages/LandingPage/AIPteMockTestPage'
import ScrollToTop from './components/ScrollToTop'
import LandingSupportPage from './pages/LandingPage/LandingSupportPage'
import WhyUsPage from './pages/LandingPage/WhyUsPage'
import StudentReviewPage from './pages/LandingPage/StudentReviewPage'
import SelfStudyPage from './pages/LandingPage/SelfStudyPage'
import PracticeAI from './pages/WeeklyPrediction/PracticeAI'
import PasswordPage from './pages/LandingPage/PasswordPage'
import ChangePasswordPage from './pages/LandingPage/ChangePasswordPage'
import AIPracticeCourse from './pages/WeeklyPrediction/AIPracticeCourse'
import SEO from './components/SEO'

interface LayoutSwitcherProps {
  children: React.ReactNode
}

const RedirectToHomeOrLanding = () => {
  const { userProfile } = useAuth()

  const hasProfile = userProfile && Object.keys(userProfile).length > 0

  if (hasProfile) {
    return <Navigate to="/home" />
  }

  return <Navigate to="/" />
}

const LayoutSwitcher: React.FC<LayoutSwitcherProps> = ({ children }) => {
  const location = useLocation()

  const landingPageRoutes = [
    '/',
    '/login',
    '/password',
    '/change-password',
    '/register',
    '/activate-account',
    '/pte-academic-speaking-test',
    '/pte-academic-writing-test',
    '/pte-academic-reading-test',
    '/pte-academic-listening-test',
    '/pte-academic-automated-machine-scoring-test',
    '/faq',
    '/work',
    '/online-ai-pte-academic-preparation-tool',
    '/contact',
    '/privacy',
    '/online-ai-pte-academic-mock-test',
    '/blog',
    '/support',
    '/whyus',
    '/pte-coaching-center-in-australia-student-reviews',
    '/pte-self-study',
  ]

  // Function to check if the route is part of the landing page layout
  const isLandingPageRoute = (path: string) => {
    // Check if the route matches a static path
    if (landingPageRoutes.includes(path)) {
      return true
    }

    // Check if the route matches the dynamic blog post path
    const blogPostRegex = /^\/blog\/post\/[a-zA-Z0-9-]+$/
    return blogPostRegex.test(path)
  }

  const useLandingPageLayout = isLandingPageRoute(location.pathname)

  if (useLandingPageLayout) {
    return <LandingPageLayout>{children}</LandingPageLayout>
  } else {
    return <Layout>{children}</Layout>
  }
}

const getPageMetadata = (path: string) => {
  switch (path) {
    // Landing Pages
    case '/':
      return { title: 'PTE Master | AI-Powered PTE Mock Tests' }
    case '/register':
      return { title: 'PTE Master | Register' }
    case '/login':
      return { title: 'PTE Master | Login' }
    case '/password':
      return { title: 'PTE Master | Reset Your Password' }
    case '/change-password':
      return { title: 'PTE Master | Change Your Password' }
    case '/faq':
      return { title: 'PTE Master | FAQs' }
    case '/work':
      return { title: 'PTE Master | How It Works' }
    case '/online-ai-pte-academic-preparation-tool':
      return { title: 'PTE Master | AI PTE Preparation Tool' }
    case '/activate-account':
      return { title: 'PTE Master | Activate Account' }
    case '/pte-academic-speaking-test':
      return { title: 'PTE Master | Speaking Test Practice' }
    case '/pte-academic-writing-test':
      return { title: 'PTE Master | Writing Test Practice' }
    case '/pte-academic-reading-test':
      return { title: 'PTE Master | Reading Test Practice' }
    case '/pte-academic-listening-test':
      return { title: 'PTE Master | Listening Test Practice' }
    case '/pte-academic-automated-machine-scoring-test':
      return { title: 'PTE Master | AI-Powered PTE Scoring' }
    case '/contact':
      return { title: 'PTE Master | Contact Us' }
    case '/privacy':
      return { title: 'PTE Master | Privacy Policy' }
    case '/online-ai-pte-academic-mock-test':
      return { title: 'PTE Master | AI PTE Mock Test' }

    // Blog Pages
    case '/blog':
      return { title: 'PTE Master | Blogs' }
    case '/blog/post/:slug':
      return { title: 'PTE Master | Blog Post' }

    // Support & Why Us
    case '/support':
      return { title: 'PTE Master | Support' }
    case '/whyus':
      return { title: 'PTE Master | Why Choose Us' }
    case '/pte-coaching-center-in-australia-student-reviews':
      return { title: 'PTE Master | Coaching Reviews' }
    case '/pte-self-study':
      return { title: 'PTE Master | Self-Study Resources' }

    // Weekly Predictions
    case '/weekly-prediction':
      return { title: 'PTE Master | Weekly Prediction' }
    case '/weekly-prediction/course':
      return { title: 'PTE Master | Weekly Prediction Course' }

    // Speaking Weekly Predictions
    case '/weekly-prediction/speaking/read-aloud':
      return { title: 'PTE Master | Read Aloud Practice' }
    case '/weekly-prediction/speaking/retell-lecture':
      return { title: 'PTE Master | Retell Lecture Practice' }
    case '/weekly-prediction/speaking/repeat-sentence':
      return { title: 'PTE Master | Repeat Sentence Practice' }
    case '/weekly-prediction/speaking/describe-image':
      return { title: 'PTE Master | Describe Image Practice' }
    case '/weekly-prediction/speaking/answer-short-question':
      return { title: 'PTE Master | Answer Short Question Practice' }

    // Reading Weekly Predictions
    case '/weekly-prediction/reading/multiple-choice-single-answer':
      return { title: 'PTE Master | Multiple Choice Single Answer' }
    case '/weekly-prediction/reading/multiple-choice-multiple-answer':
      return { title: 'PTE Master | Multiple Choice Multiple Answer' }
    case '/weekly-prediction/reading/reorder-paragraph':
      return { title: 'PTE Master | Reorder Paragraph' }
    case '/weekly-prediction/reading/reading-and-writing-fill-in-the-blanks':
      return { title: 'PTE Master | Reading & Writing Fill In The Blanks' }
    case '/weekly-prediction/reading/reading-fill-in-the-blanks':
      return { title: 'PTE Master | Reading Fill In The Blanks' }

    // Writing Weekly Predictions
    case '/weekly-prediction/writing/summarize-written-text':
      return { title: 'PTE Master | Summarize Written Text' }
    case '/weekly-prediction/writing/write-essay':
      return { title: 'PTE Master | Write Essay' }

    // Listening Weekly Predictions
    case '/weekly-prediction/listening/summarize-spoken-text':
      return { title: 'PTE Master | Summarize Spoken Text' }
    case '/weekly-prediction/listening/fill-in-the-blanks':
      return { title: 'PTE Master | Fill In The Blanks Listening' }
    case '/weekly-prediction/listening/highlight-correct-summary':
      return { title: 'PTE Master | Highlight Correct Summary' }
    case '/weekly-prediction/listening/highlight-incorrect-words':
      return { title: 'PTE Master | Highlight Incorrect Words' }
    case '/weekly-prediction/listening/multiple-choice-choose-multiple-answers':
      return { title: 'PTE Master | Multiple Choice Choose Multiple Answers' }
    case '/weekly-prediction/listening/multiple-choice-choose-single-answer':
      return { title: 'PTE Master | Multiple Choice Choose Single Answer' }
    case '/weekly-prediction/listening/select-missing-word':
      return { title: 'PTE Master | Select Missing Word' }
    case '/weekly-prediction/listening/write-from-dictation':
      return { title: 'PTE Master | Write From Dictation' }

    // Shop & Purchases
    case '/shop':
      return { title: 'PTE Master | Shop' }
    case '/purchase':
      return { title: 'PTE Master | Purchase' }
    case '/purchase/succeed':
      return { title: 'PTE Master | Purchase Succeed' }
    case '/purchase/cancel':
      return { title: 'PTE Master | Purchase Canceled' }
    case '/cart':
      return { title: 'PTE Master | Shopping Cart' }

    // Performance Tracking
    case '/my-performance':
      return { title: 'PTE Master | My Performance' }
    case '/practice-performance':
      return { title: 'PTE Master | Practice Performance' }

    // User Account & Profile
    case '/user-profile':
      return { title: 'PTE Master | User Profile' }
    case '/my-unlocked-test':
      return { title: 'PTE Master | My Unlocked Tests' }
    case '/last-minute-study':
      return { title: 'PTE Master | Last Minute Study' }
    case '/mock-test/history':
      return { title: 'PTE Master | Your Mock Test History' }

    // Community Pages
    case '/pte-videos':
      return { title: 'PTE Master | PTE Videos' }

    // Plans & Subscription Pages
    case '/plan':
      return { title: 'PTE Master | Plans & Pricing' }
    case '/plan/:planId':
      return { title: 'PTE Master | Plan Details' }

    // Practice Test Pages
    case '/practice':
      return { title: 'PTE Master | Practice' }
    case '/practice/course':
      return { title: 'PTE Master | Practice Course' }

    // Sectional Test Pages
    case '/sectional-test':
      return { title: 'PTE Master | Read Aloud Sectional Test' }

    // Mock Test Pages

    default:
      return { title: 'PTE Master | AI-Powered PTE Training' }
  }
}

// Component to update title based on current route
const TitleUpdater = () => {
  const location = useLocation()
  const { title } = getPageMetadata(location.pathname)
  return <SEO title={title} />
}

function App() {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'EducationalOrganization',
    name: 'PTE Master',
    url: 'https://www.ptemaster.com',
    logo: 'https://www.ptemaster.com/logo.png',
    description:
      'PTE Master provides AI-powered PTE mock tests and training courses to help students achieve their target PTE scores.',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Level 36 Gateway, 1 Macquarie Place',
      addressLocality: 'Sydney',
      addressRegion: 'NSW',
      postalCode: '2000',
      addressCountry: 'Australia',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+61 411 703 756',
      contactType: 'customer support',
      availableLanguage: ['English'],
    },
    sameAs: [
      'https://www.facebook.com/ptemaster',
      'https://www.linkedin.com/company/ptemaster',
      'https://www.youtube.com/ptemaster',
    ],
    offers: {
      '@type': 'Offer',
      name: 'PTE AI Mock Test',
      url: 'https://www.ptemaster.com/mock-test',
      price: '15.00',
      priceCurrency: 'AUD',
      availability: 'https://schema.org/InStock',
      validFrom: '2024-01-01',
    },
  }

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <TitleUpdater />

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(schemaData)}
            </script>
          </Helmet>
          <ApiProvider>
            <AuthProvider>
              {/* <WebSocketProvider
                url={process.env.REACT_APP_WEB_SOCKET_URL || ''}
              > */}
                <LayoutSwitcher>
                  <Routes>
                    <Route
                      path={Path.landing.main.path}
                      element={
                        <RedirectIfAuthenticated>
                          <LandingPage />
                        </RedirectIfAuthenticated>
                      }
                    />
                    <Route
                      path={Path.landing.login.path}
                      element={
                        <RedirectIfAuthenticated>
                          <LoginPage />
                        </RedirectIfAuthenticated>
                      }
                    />
                    <Route
                      path={Path.landing.forgotPassword.path}
                      element={<PasswordPage />}
                    />
                    <Route
                      path={Path.landing.changePassword.path}
                      element={<ChangePasswordPage />}
                    />
                    <Route
                      path={Path.landing.register.path}
                      element={
                        <RedirectIfAuthenticated>
                          <RegisterPage />
                        </RedirectIfAuthenticated>
                      }
                    />
                    <Route
                      path={Path.landing.activateAccount.path}
                      element={
                        <RedirectIfAuthenticated>
                          <ActivateAccountPage />
                        </RedirectIfAuthenticated>
                      }
                    />
                    <Route
                      path={Path.landing.faqs.path}
                      element={<FAQsPage />}
                    />
                    <Route
                      path={Path.landing.howItWorks.path}
                      element={<HowItWorksPage />}
                    />
                    <Route
                      path={Path.landing.prepareTools.path}
                      element={<PreparationToolPage />}
                    />
                    <Route
                      path={Path.landing.contactUs.path}
                      element={<ContactUsPage />}
                    />
                    <Route
                      path={Path.landing.privacyPolicy.path}
                      element={<PrivacyPolicyPage />}
                    />
                    <Route
                      path={Path.landing.aiPteMockTest.path}
                      element={<AIPteMockTestPage />}
                    />
                    <Route
                      path={Path.landing.blog.main.path}
                      element={<BlogPage />}
                    />
                    <Route
                      path={Path.landing.blog.post.path}
                      element={<BlogPostPage />}
                    />
                    <Route
                      path={Path.landing.support.path}
                      element={<LandingSupportPage />}
                    />
                    <Route
                      path={Path.landing.whyUs.path}
                      element={<WhyUsPage />}
                    />
                    <Route
                      path={Path.landing.pteSelfStudy.path}
                      element={<SelfStudyPage />}
                    />
                    <Route
                      path={Path.landing.studentReviews.path}
                      element={<StudentReviewPage />}
                    />
                    <Route
                      path={Path.landing.academicSpeaking.path}
                      element={<AcademicSpeakingPage />}
                    />
                    <Route
                      path={Path.landing.academicListening.path}
                      element={<AcademicListeningPage />}
                    />
                    <Route
                      path={Path.landing.academicReading.path}
                      element={<AcademicReadingPage />}
                    />
                    <Route
                      path={Path.landing.academicWriting.path}
                      element={<AcademicWritingPage />}
                    />
                    <Route
                      path={Path.landing.automateScoring.path}
                      element={<AutomateScoringPage />}
                    />
                    <Route
                      path={Path.home.path}
                      element={<ProtectedRoute component={HomePage} />}
                    />
                    <Route
                      path={Path.weeklyPrediction.path}
                      element={<ProtectedRoute component={WeeklyPrediction} />}
                    />
                    <Route
                      path={Path.weeklyPredictionCourse.path}
                      element={
                        <ProtectedRoute component={WeeklyPredictionCourse} />
                      }
                    />
                    <Route
                      path={Path.readAloud.path}
                      element={<ProtectedRoute component={ReadAloud} />}
                    />
                    <Route
                      path={Path.retellLecture.path}
                      element={<ProtectedRoute component={ReTellLecture} />}
                    />
                    <Route
                      path={Path.repeatSentence.path}
                      element={<ProtectedRoute component={RepeatSentence} />}
                    />
                    <Route
                      path={Path.describeImage.path}
                      element={<ProtectedRoute component={DescribeImage} />}
                    />
                    <Route
                      path={Path.answerShortQuestion.path}
                      element={<ProtectedRoute component={AnswerShort} />}
                    />
                    <Route
                      path={Path.multipleChoiceSingleAnswer.path}
                      element={<ProtectedRoute component={SingleAnswerMC} />}
                    />
                    <Route
                      path={Path.multipleChoiceMultipleAnswer.path}
                      element={<ProtectedRoute component={MultipleAnswerMC} />}
                    />
                    <Route
                      path={Path.reorderParagraph.path}
                      element={<ProtectedRoute component={ReorderParagraph} />}
                    />
                    <Route
                      path={Path.fillInTheBlank.path}
                      element={<ProtectedRoute component={FillInTheBlank} />}
                    />
                    <Route
                      path={Path.readingFillInTheBlank.path}
                      element={
                        <ProtectedRoute component={ReadingFillInTheBlank} />
                      }
                    />
                    <Route
                      path={Path.summarizeWrittenText.path}
                      element={
                        <ProtectedRoute component={SummarizeWrittenText} />
                      }
                    />
                    <Route
                      path={Path.writeEssay.path}
                      element={<ProtectedRoute component={WriteEssay} />}
                    />
                    <Route
                      path={Path.summarizeSpokenText.path}
                      element={
                        <ProtectedRoute component={SummarizeSpokenText} />
                      }
                    />
                    <Route
                      path={Path.fillInTheBlanks.path}
                      element={<ProtectedRoute component={FillInTheBlanks} />}
                    />
                    <Route
                      path={Path.highlightCorrectSummary.path}
                      element={
                        <ProtectedRoute component={HighlightCorrectSummary} />
                      }
                    />
                    <Route
                      path={Path.highlightIncorrectWords.path}
                      element={
                        <ProtectedRoute component={HighlightIncorrectWords} />
                      }
                    />
                    <Route
                      path={Path.multipleChoiceChooseMultipleAnswers.path}
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseMultipleAnswers}
                        />
                      }
                    />
                    <Route
                      path={Path.multipleChoiceChooseSingleAnswer.path}
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseSingleAnswer}
                        />
                      }
                    />
                    <Route
                      path={Path.selectMissingWord.path}
                      element={<ProtectedRoute component={SelectMissingWord} />}
                    />
                    <Route
                      path={Path.writeFromDictation.path}
                      element={
                        <ProtectedRoute component={WriteFromDictation} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.readAloud.path}
                      element={
                        <ProtectedRoute component={SectionalReadAloud} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.repeatSentence.path}
                      element={
                        <ProtectedRoute component={SectionalRepeatSentence} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.describeImage.path}
                      element={
                        <ProtectedRoute component={SectionalDescribeImage} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.answerShortQuestion.path}
                      element={
                        <ProtectedRoute component={SectionalAnswerShort} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.retellLecture.path}
                      element={
                        <ProtectedRoute component={SectionalReTellLecture} />
                      }
                    />
                    <Route
                      path={
                        Path.sectionalTest.readingMultipleChoiceMultipleAnswer
                          .path
                      }
                      element={
                        <ProtectedRoute component={SectionalMultipleAnswerMC} />
                      }
                    />
                    <Route
                      path={
                        Path.sectionalTest.readingMultipleChoiceSingleAnswer
                          .path
                      }
                      element={
                        <ProtectedRoute component={SectionalSingleAnswerMC} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.reorderParagraph.path}
                      element={
                        <ProtectedRoute component={SectionalReorderParagraph} />
                      }
                    />
                    <Route
                      path={
                        Path.sectionalTest.readingAndWritingFillInTheBlanks.path
                      }
                      element={
                        <ProtectedRoute component={SectionalFillInTheBlank} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.readingFillInTheBlanks.path}
                      element={
                        <ProtectedRoute
                          component={SectionalReadingFillInTheBlank}
                        />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.summarizeWrittenText.path}
                      element={
                        <ProtectedRoute
                          component={SummarizeWrittenTextSectional}
                        />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.writeEssay.path}
                      element={
                        <ProtectedRoute component={WriteEssaySectional} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.summarizeSpokenText.path}
                      element={
                        <ProtectedRoute
                          component={SummarizeSpokenTextSectional}
                        />
                      }
                    />
                    <Route
                      path={
                        Path.sectionalTest.multipleChoiceChooseMultipleAnswers
                          .path
                      }
                      element={
                        <ProtectedRoute
                          component={
                            MultipleChoiceChooseMultipleAnswersSectional
                          }
                        />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.fillInTheBlanks.path}
                      element={
                        <ProtectedRoute component={FillInTheBlanksSectional} />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.highlightCorrectSummary.path}
                      element={
                        <ProtectedRoute
                          component={HighlightCorrectSummarySectional}
                        />
                      }
                    />
                    <Route
                      path={
                        Path.sectionalTest.multipleChoiceChooseSingleAnswer.path
                      }
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseSingleAnswerSectional}
                        />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.selectMissingWord.path}
                      element={
                        <ProtectedRoute
                          component={SelectMissingWordSectional}
                        />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.highlightIncorrectWords.path}
                      element={
                        <ProtectedRoute
                          component={HighlightIncorrectWordsSectional}
                        />
                      }
                    />
                    <Route
                      path={Path.sectionalTest.writeFromDictation.path}
                      element={
                        <ProtectedRoute
                          component={WriteFromDictationSectional}
                        />
                      }
                    />
                    <Route
                      path={Path.shop.path}
                      element={<ProtectedRoute component={ShopPage} />}
                    />
                    <Route
                      path={Path.myUnlockedTests.path}
                      element={
                        <ProtectedRoute component={MyUnlockedTestsPage} />
                      }
                    />
                    <Route
                      path={Path.lastMinuteStudy.path}
                      element={
                        <ProtectedRoute component={LastMinuteStudyPage} />
                      }
                    />
                    <Route
                      path={Path.community.pteVideos.path}
                      element={<ProtectedRoute component={PTEVideosPage} />}
                    />
                    <Route
                      path={Path.systemRequirement.sectional.speaking.path}
                      element={
                        <ProtectedRoute component={SRSectionalSpeakingPage} />
                      }
                    />
                    <Route
                      path={Path.systemRequirement.sectional.writing.path}
                      element={
                        <ProtectedRoute component={SRSectionaWritingPage} />
                      }
                    />
                    <Route
                      path={Path.systemRequirement.sectional.reading.path}
                      element={
                        <ProtectedRoute component={SRSectionalReadingPage} />
                      }
                    />
                    <Route
                      path={Path.systemRequirement.sectional.listening.path}
                      element={
                        <ProtectedRoute component={SRSectionalListeningPage} />
                      }
                    />
                    <Route
                      path={Path.systemRequirement.mock.path}
                      element={<ProtectedRoute component={SRMockTestPage} />}
                    />
                    <Route
                      path={Path.yourPurchase.path}
                      element={<ProtectedRoute component={PurchasePage} />}
                    />
                    <Route
                      path={Path.purchaseSucceed.path}
                      element={
                        <ProtectedRoute component={PurchaseSucceedPage} />
                      }
                    />
                    <Route
                      path={Path.purchaseCancel.path}
                      element={
                        <ProtectedRoute component={PurchaseCancelPage} />
                      }
                    />
                    <Route
                      path={Path.cart.path}
                      element={<ProtectedRoute component={CartPage} />}
                    />
                    <Route
                      path={Path.myPerformance.path}
                      element={<ProtectedRoute component={MyPerformancePage} />}
                    />
                    <Route
                      path={Path.practicePerformance.path}
                      element={
                        <ProtectedRoute component={PracticePerformancePage} />
                      }
                    />
                    {/* <Route
                  path={Path.liveChat.path}
                  element={<ProtectedRoute component={LiveChatPage} />}
                /> */}
                    <Route
                      path={Path.support.path}
                      element={<ProtectedRoute component={SupportPage} />}
                    />
                    <Route
                      path={Path.userProfile.path}
                      element={<ProtectedRoute component={UserProfilePage} />}
                    />
                    <Route
                      path={Path.mockTest.summarizeWrittenText.path}
                      element={
                        <ProtectedRoute component={SummarizeWrittenTextMock} />
                      }
                    />
                    <Route
                      path={Path.mockTest.writeEssay.path}
                      element={<ProtectedRoute component={WriteEssayMock} />}
                    />
                    <Route
                      path={Path.mockTest.summarizeSpokenText.path}
                      element={
                        <ProtectedRoute component={SummarizeSpokenTextMock} />
                      }
                    />
                    <Route
                      path={
                        Path.mockTest.multipleChoiceChooseMultipleAnswers.path
                      }
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseMultipleAnswersMock}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.fillInTheBlanks.path}
                      element={
                        <ProtectedRoute component={FillInTheBlanksMock} />
                      }
                    />
                    <Route
                      path={Path.mockTest.highlightCorrectSummary.path}
                      element={
                        <ProtectedRoute
                          component={HighlightCorrectSummaryMock}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.multipleChoiceChooseSingleAnswer.path}
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseSingleAnswerMock}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.selectMissingWord.path}
                      element={
                        <ProtectedRoute component={SelectMissingWordMock} />
                      }
                    />
                    <Route
                      path={Path.mockTest.highlightIncorrectWords.path}
                      element={
                        <ProtectedRoute
                          component={HighlightIncorrectWordsMock}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.writeFromDictation.path}
                      element={
                        <ProtectedRoute component={WriteFromDictationMock} />
                      }
                    />
                    <Route
                      path={Path.mockTest.readAloud.path}
                      element={
                        <ProtectedRoute component={MockReadAloudQuestion} />
                      }
                    />
                    <Route
                      path={Path.mockTest.repeatSentence.path}
                      element={
                        <ProtectedRoute
                          component={MockRepeatSentenceQuestion}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.describeImage.path}
                      element={
                        <ProtectedRoute component={MockDescribeImageQuestion} />
                      }
                    />
                    <Route
                      path={Path.mockTest.retellLecture.path}
                      element={
                        <ProtectedRoute component={MockReTellLectureQuestion} />
                      }
                    />
                    <Route
                      path={Path.mockTest.answerShortQuestion.path}
                      element={
                        <ProtectedRoute component={MockAnswerShortQuestion} />
                      }
                    />
                    <Route
                      path={
                        Path.mockTest.readingMultipleChoiceSingleAnswer.path
                      }
                      element={
                        <ProtectedRoute component={MockSingleMCQuestion} />
                      }
                    />
                    <Route
                      path={
                        Path.mockTest.readingMultipleChoiceMultipleAnswer.path
                      }
                      element={
                        <ProtectedRoute component={MockMultipleMCQuestion} />
                      }
                    />
                    <Route
                      path={Path.mockTest.reorderParagraph.path}
                      element={
                        <ProtectedRoute
                          component={MockReorderParagraphQuestion}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.readingAndWritingFillInTheBlanks.path}
                      element={
                        <ProtectedRoute
                          component={MockFillInTheBlankQuesiton}
                        />
                      }
                    />
                    <Route
                      path={Path.mockTest.readingFillInTheBlanks.path}
                      element={
                        <ProtectedRoute
                          component={MockReadingFillInTheBlanksQuestion}
                        />
                      }
                    />
                    <Route
                      path={Path.historyDetailsMock.path}
                      element={
                        <ProtectedRoute component={HistoryDetailsMockPage} />
                      }
                    />
                    <Route
                      path={Path.testHistoryMock.path}
                      element={
                        <ProtectedRoute component={TestHistoryMockPage} />
                      }
                    />
                    <Route
                      path={Path.testHistorySectional.path}
                      element={
                        <ProtectedRoute component={TestHistorySectionalPage} />
                      }
                    />
                    <Route
                      path={Path.reportHistorySectional.speaking.path}
                      element={
                        <ProtectedRoute
                          component={ReportHistorySpeakingSectionalPage}
                        />
                      }
                    />
                    <Route
                      path={Path.reportHistorySectional.writing.path}
                      element={
                        <ProtectedRoute
                          component={ReportHistoryWritingSectionalPage}
                        />
                      }
                    />
                    <Route
                      path={Path.reportHistorySectional.listening.path}
                      element={
                        <ProtectedRoute
                          component={ReportHistoryListeningSectionalPage}
                        />
                      }
                    />
                    <Route
                      path={Path.reportHistorySectional.reading.path}
                      element={
                        <ProtectedRoute
                          component={ReportHistoryReadingSectionalPage}
                        />
                      }
                    />
                    <Route
                      path={Path.planPages.main.path}
                      element={<ProtectedRoute component={PlanPage} />}
                    />
                    <Route
                      path={Path.planPages.detail.path}
                      element={<ProtectedRoute component={PlanDetailPage} />}
                    />
                    <Route
                      path={Path.practice.path}
                      element={<ProtectedRoute component={PracticeAI} />}
                    />
                    <Route
                      path={Path.practiceCourse.path}
                      element={<ProtectedRoute component={AIPracticeCourse} />}
                    />
                    <Route
                      path={Path.practiceTest.readAloud.path}
                      element={<ProtectedRoute component={ReadAloud} />}
                    />
                    <Route
                      path={Path.practiceTest.retellLecture.path}
                      element={<ProtectedRoute component={ReTellLecture} />}
                    />
                    <Route
                      path={Path.practiceTest.repeatSentence.path}
                      element={<ProtectedRoute component={RepeatSentence} />}
                    />
                    <Route
                      path={Path.practiceTest.describeImage.path}
                      element={<ProtectedRoute component={DescribeImage} />}
                    />
                    <Route
                      path={Path.practiceTest.answerShortQuestion.path}
                      element={<ProtectedRoute component={AnswerShort} />}
                    />
                    <Route
                      path={Path.practiceTest.multipleChoiceSingleAnswer.path}
                      element={<ProtectedRoute component={SingleAnswerMC} />}
                    />
                    <Route
                      path={Path.practiceTest.multipleChoiceMultipleAnswer.path}
                      element={<ProtectedRoute component={MultipleAnswerMC} />}
                    />
                    <Route
                      path={Path.practiceTest.reorderParagraph.path}
                      element={<ProtectedRoute component={ReorderParagraph} />}
                    />
                    <Route
                      path={Path.practiceTest.fillInTheBlank.path}
                      element={<ProtectedRoute component={FillInTheBlank} />}
                    />
                    <Route
                      path={Path.practiceTest.readingFillInTheBlank.path}
                      element={
                        <ProtectedRoute component={ReadingFillInTheBlank} />
                      }
                    />
                    <Route
                      path={Path.practiceTest.summarizeWrittenText.path}
                      element={
                        <ProtectedRoute component={SummarizeWrittenText} />
                      }
                    />
                    <Route
                      path={Path.practiceTest.writeEssay.path}
                      element={<ProtectedRoute component={WriteEssay} />}
                    />
                    <Route
                      path={Path.practiceTest.summarizeSpokenText.path}
                      element={
                        <ProtectedRoute component={SummarizeSpokenText} />
                      }
                    />
                    <Route
                      path={Path.practiceTest.fillInTheBlanks.path}
                      element={<ProtectedRoute component={FillInTheBlanks} />}
                    />
                    <Route
                      path={Path.practiceTest.highlightCorrectSummary.path}
                      element={
                        <ProtectedRoute component={HighlightCorrectSummary} />
                      }
                    />
                    <Route
                      path={Path.practiceTest.highlightIncorrectWords.path}
                      element={
                        <ProtectedRoute component={HighlightIncorrectWords} />
                      }
                    />
                    <Route
                      path={
                        Path.practiceTest.multipleChoiceChooseMultipleAnswers
                          .path
                      }
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseMultipleAnswers}
                        />
                      }
                    />
                    <Route
                      path={
                        Path.practiceTest.multipleChoiceChooseSingleAnswer.path
                      }
                      element={
                        <ProtectedRoute
                          component={MultipleChoiceChooseSingleAnswer}
                        />
                      }
                    />
                    <Route
                      path={Path.practiceTest.selectMissingWord.path}
                      element={<ProtectedRoute component={SelectMissingWord} />}
                    />
                    <Route
                      path={Path.practiceTest.writeFromDictation.path}
                      element={
                        <ProtectedRoute component={WriteFromDictation} />
                      }
                    />
                    <Route path="*" element={<RedirectToHomeOrLanding />} />
                  </Routes>
                </LayoutSwitcher>
              {/* </WebSocketProvider> */}
            </AuthProvider>
          </ApiProvider>
        </BrowserRouter>
        <ToastContainer />
      </HelmetProvider>
    </>
  )
}

export default App
