import LoginForm from '../../components/LandingPage/Auth/LoginForm'

const RegisterPage: React.FC = () => {
  return (
    <div className="login-section lg:h-screen h-full bg-[#F5F5F5] flex flex-col justify-center items-center lg:py-0 py-[2rem] lg:px-0 px-[1rem]">
      <p className="text-h1 font-landingLogo text-center mb-4">Log In</p>
      <LoginForm />
    </div>
  )
}

export default RegisterPage
