import { useState, useEffect, useRef } from 'react'
import { ReactComponent as ArrowRightIcon } from '../../../images/SectionalTest/st-arrow-right-icon.svg'
import AudioSectional from '../../../components/SectionalTest/AudioSectional'
import { useNavigate } from 'react-router-dom'
import Option from '../../../models/option.model'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import {
  doTest5Update,
  addResultListeningSectional,
} from '../../../services/sectionalTest.services'
import { useAuth } from '../../../providers/AuthProvider'
import {
  handleExceptionError,
  navigateToSectionalTestListening,
} from '../../../utils/utils'

const MultipleChoiceChooseSingleAnswerSectional = () => {
  const navigate = useNavigate()

  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(
    undefined,
  )
  const [originalOptions, setOriginalOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  )
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { userProfile } = useAuth()

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)
  const audioRef = useRef<{ resetAudio: () => void }>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fetchData = async () => {
      const reseultData = localStorage.getItem('resultSectionalLPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setOriginalOptions(parsedResultData.option)
        setTotalQuestion(Number(parsedResultData.allcount))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const handleChooseOption = (option: Option) => {
    setSelectedOption(option)
  }

  const resetState = () => {
    setIsSubmitted(false)
    setSelectedOption(undefined)
    elapsedTimeRef.current = 0

    if (audioRef.current) {
      audioRef.current.resetAudio()
    }

    setTimer({ minutes: 40, seconds: 0 })

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }

  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && selectedOption && userProfile && userProfile.userId) {
      try {
        const addResultResponse = await addResultListeningSectional(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          String(selectedOption.correct),
          selectedOption.options,
          selectedOption.correct,
          '',
        )

        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest5Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            4,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip',
          )

          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultSectionalLPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '21') {
              setQuestion(getQuestionResponse.data.result.mockqestion[0])
              setQuestionNumber(getQuestionResponse.data.result.row)
              setOriginalOptions(getQuestionResponse.data.result.option)
              setTotalQuestion(Number(getQuestionResponse.data.result.allcount))
              resetState()
            } else {
              navigateToSectionalTestListening(
                navigate,
                getQuestionResponse.data.category_id,
              )
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or selected option not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId) {
      try {
        const getQuestionResponse = await doTest5Update(
          question.buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          4,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip',
        )

        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultSectionalLPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '21') {
            setQuestion(getQuestionResponse.data.result.mockqestion[0])
            setQuestionNumber(getQuestionResponse.data.result.row)
            setOriginalOptions(getQuestionResponse.data.result.option)
            setTotalQuestion(Number(getQuestionResponse.data.result.allcount))
            resetState()
          } else {
            navigateToSectionalTestListening(
              navigate,
              getQuestionResponse.data.category_id,
            )
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div
          className="st-mccsa w-full pb-4 shadow-question-card mx-auto font-landingPrimary"
          ref={questionSectionRef}
        >
          <div className="st-mccsa__header flex justify-between gap-2 p-4 mb-4 bg-info rounded-lg text-white text-h5r">
            <p className="st-mccsa__header__title">
              Listening Section - Multiple-Choice Choose Single Answer (#
              {question?.id})
            </p>
            <p className="st-mccsa__header__number">
              ({questionNumber + 1} Out of 18)
            </p>
          </div>
          <div className="st-sst__timer w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
            <p className="text-h2 text-white">{formattedTime}</p>
          </div>
          <div className="st-mccsa__instruction text-h4m px-4 mb-4">
            {question?.shortTitle}
          </div>
          <AudioSectional
            ref={audioRef}
            audioUrl={question && question.audio ? question.audio : ''}
          />
          <div className="st-mccsa__question mx-auto mb-6 lg:min-w-[30.125rem] w-80">
            <p className="st-mccsa__question__detail text-h5m">
              {question?.question}
            </p>
            <div className="st-mccsa__question__options flex flex-col gap-2 mt-2">
              {originalOptions.map((option: Option, index) => (
                <div
                  key={option.id}
                  className="options__item flex items-center gap-4"
                >
                  <input
                    data-testid={`option-${index}`}
                    type="radio"
                    id={String(option.id)}
                    name="mccsa_options"
                    value={option.id}
                    className="accent-primary-6 w-4 h-4 flex-shrink-0"
                    onChange={() => handleChooseOption(option)}
                  />
                  <label
                    htmlFor={String(option.id)}
                    className="flex-1 text-bodyr"
                  >
                    {option.options}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-center gap-4">
            <button
              data-testid="save-and-next"
              className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg 
                ${!selectedOption || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
              onClick={handleSubmitAnswer}
              disabled={!selectedOption || isSubmitted}
            >
              Save and Next
              <ArrowRightIcon />
            </button>
            <button
              data-testid="skip"
              className={`flex items-center py-2 px-6 rounded-lg 
                ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              disabled={isSubmitted}
              onClick={handleSkip}
            >
              <p className="text-bodyr text-white">Skip</p>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MultipleChoiceChooseSingleAnswerSectional
